import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import SlideComponentArabic from '@/components/SliderComponentArabic.vue'
import SliderComponent from '@/components/SliderComponent.vue' // Import SliderComponentEnglish

const routes = [
    { path: '/', component: HomePage },
    {
        path: '/arabic',
        name: 'Arabic',
        component: SlideComponentArabic
    },
    {
        path: '/english',
        name: 'English',
        component: SliderComponent
    },
    
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router

<template>
  <div class="min-h-screen bg-custom-img bg-cover bg-center overflow-hidden">
    <NavBar />
    <router-view /> <!-- This is where the routed components will be rendered -->
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'; // Import the Navbar component

export default {
  name: 'App',
  components: {
    NavBar // Register the Navbar component
  }
}
</script>

<style>
/* Add global styles here */
.bg-custom-img {
  background-image: url('@/assets/texture.png');
}
</style>

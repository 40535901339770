import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import router from './router' // Import router configuration


createApp(App).use(router).mount('#app')



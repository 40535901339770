<template>
  <nav class="bg-custom-nav flex items-center justify-between ">
    <!-- Logo container -->
    <div class="flex items-center justify-center flex-grow">
      <router-link to="/" class="flex items-center justify-center">
        <div class="bg-gray-100 rounded-md mt-5 mb-0 opacity-80">
        <img src="@/assets/wali-text.png" alt="Logo" class="h-14">
        </div>
      </router-link>
      <div>
        <img src="@/assets/wali_logo.png" alt="Logo" class="ml-5 mt-5 h-28">
        </div>
    </div>
    <!-- Buttons for large screens -->
   

    <!-- Menu button for small screens -->
    
    <!-- Dropdown menu for small screens -->
    
    
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    }
  }
}
</script>

<style scoped>
.bg-custom-nav {
  background-image: url('@/assets/texture.png');
  background-size: cover; /* Make sure the background image covers the entire screen */
  background-position: center; /* Center the background image */
}
</style>

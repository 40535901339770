<template>
    <div class="h-auto m-8">
        <ImageGallery />
    </div>
    <div class=" flex justify-center ">
        <router-link to="/arabic" class="menu-link">منيو</router-link>
        <router-link to="/english" class="menu-link">Menu</router-link>
    </div>
    <div class="mb-8"></div>
</template>

<script>
import ImageGallery from './ImageGallery.vue';

export default {
    name: 'Homepage',
    components: {
        ImageGallery,
    },
    data() {
        return {
            images: [ // Placeholder images
                { id: 1, url: 'https://via.placeholder.com/150' },
                { id: 2, url: 'https://via.placeholder.com/150' },
                { id: 3, url: 'https://via.placeholder.com/150' },
                { id: 4, url: 'https://via.placeholder.com/150' },
                { id: 5, url: 'https://via.placeholder.com/150' },
                { id: 6, url: 'https://via.placeholder.com/150' },
                // Add more placeholder images as needed
            ]
        };
    }
}
</script>

<style scoped>
/* Add custom styles here if needed */
.menu-link {
    display: inline-block;
    background-color:#c05621;
    color: white;
    font-weight: bold;
    padding: 8px 16px;
    margin: 0 8px;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    min-width: 100px;
    /* Set a minimum width */
}
</style>

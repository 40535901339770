<template>
    <div class="gallery-container">
        <!-- Gallery Carousel with Navigation Buttons -->
        <div class="mt-8 relative flex justify-center items-center">
            <!-- Left Navigation Button -->
            <button @click="prevSlide"
                class="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white opacity-50 p-2 rounded-md shadow-md">
                &lt;
            </button>

            <!-- Gallery Carousel -->
            <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide" class="m-6">
                <Slide v-for="(video, index) in galleryVideos" :key="index">
                    <div class="carousel__item" @click="showModal = true; selectedVideoIndex = index">
                        <video autoplay muted :src="video.src" @ended="playNextVideo(index)"
                            class="h-fit rounded-lg shadow-lg"></video>
                    </div>
                </Slide>
            </Carousel>

            <!-- Right Navigation Button -->
            <button @click="nextSlide"
                class="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white opacity-50 p-2 rounded-md shadow-md">
                &gt;
            </button>
        </div>

        <!-- Modal for Zoomed Video -->
        <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
            <div class="max-w-full max-h-full overflow-auto">
                <button @click="showModal = false"
                    class="absolute top-0 right-0 m-4 text-orange-950 text-3xl  bg-white opacity-50 rounded-lg shadow-lg">&times;</button>
                <video autoplay muted :src="galleryVideos[selectedVideoIndex].src"
                    class="w-full h-80 object-cover"></video>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
    name: 'Gallery',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            currentSlide: 0,
            showModal: false,
            selectedVideoIndex: 0,
            galleryVideos: [
                { src: require('@/assets/wali/vid-1.mp4') },
                { src: require('@/assets/wali/vid-2.mp4') },
                { src: require('@/assets/wali/vid-3.mp4') },
                // Add more gallery video URLs as needed
            ],
        };
    },
    methods: {
        slideTo(index) {
            this.currentSlide = index;
        },
        prevSlide() {
            this.currentSlide = Math.max(this.currentSlide - 1, 0);
        },
        nextSlide() {
            this.currentSlide = Math.min(this.currentSlide + 1, this.galleryVideos.length - 1);
        },
        playNextVideo(index) {
            if (index < this.galleryVideos.length - 1) {
                this.currentSlide = index + 1;
            }
        },
    },
});
</script>

<style scoped>
.gallery-container {
    max-width: 800px;
    margin: 0 auto;
}

.carousel__item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__item video {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.carousel__item video:hover {
    transform: scale(1.05);
}
</style>
<template>
    <div class="gallery-container">
        <!-- Gallery Carousel with Navigation Buttons -->
        <div class=" mt-8 relative">
            <!-- Left Navigation Button -->
            <button @click="prevSlide"
                class="absolute m-2 left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white opacity-50 p-2 rounded-md shadow-md">
                &lt;
            </button>

            <!-- Gallery Carousel -->
            <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide" class="mb-6">
                <Slide v-for="(image, index) in galleryImages" :key="index">
                    <div class="carousel__item" @click="showModal = true; selectedImageIndex = index">
                        <img :src="image" alt="Gallery Image" class="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                </Slide>
            </Carousel>

            <!-- Right Navigation Button -->
            <button @click="nextSlide"
                class="absolute m-2 right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white opacity-50 p-2 rounded-md shadow-md">
                &gt;
            </button>
        </div>

        <!-- Thumbnails Carousel with Navigation Buttons -->
        <Carousel id="thumbnails" :items-to-show="4" :wrap-around="true" v-model="currentSlide" ref="carousel">
            <Slide v-for="(image, index) in thumbnailImages" :key="index">
                <div class="carousel__item" @click="slideTo(index)">
                    <img :src="image" alt="Thumbnail Image" class="w-full h-auto rounded-lg shadow-md cursor-pointer" />
                </div>
            </Slide>

            <!-- Navigation buttons for the thumbnails carousel -->

        </Carousel>

        <!-- Modal for Zoomed Image -->
        <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
            <div class="max-w-full max-h-full overflow-auto">
                <button @click="showModal = false"
                    class="absolute top-0 right-0 m-4 text-blue-700 text-3xl  bg-white opacity-50 rounded-lg shadow-lg">&times;</button>
                <img :src="galleryImages[selectedImageIndex]" alt="Zoomed Image" class="max-w-full max-h-full" />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
    name: 'Gallery',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            currentSlide: 0,
            showModal: false,
            selectedImageIndex: 0,
            galleryImages: [
                require('@/assets/English-Final/intro.jpg'),
                require('@/assets/English-Final/1 - front.jpg'),
                require('@/assets/English-Final/1 - back.jpg'),
                require('@/assets/English-Final/2-front.jpg'),
                require('@/assets/English-Final/2-back.jpg'),
                require('@/assets/English-Final/3-front.jpg'),
                require('@/assets/English-Final/3-back.jpg'),
                require('@/assets/English-Final/4-front.jpg'),
                require('@/assets/English-Final/4-back.jpg'),
                require('@/assets/English-Final/5-front.jpg'),
                require('@/assets/English-Final/5-back.jpg'),
                require('@/assets/English-Final/6-front.jpg'),
                require('@/assets/English-Final/6-back.jpg'),
                require('@/assets/English-Final/7-front.jpg'),
                require('@/assets/English-Final/7-back.jpg'),
                require('@/assets/English-Final/8-front.jpg'),
                require('@/assets/English-Final/8-back.jpg'),
                require('@/assets/English-Final/9-front.jpg'),
                require('@/assets/English-Final/9-back.jpg'),
                // Add more gallery image URLs as needed
            ],
            thumbnailImages: [
                require('@/assets/English-Final/intro.jpg'),
                require('@/assets/English-Final/1 - front.jpg'),
                require('@/assets/English-Final/1 - back.jpg'),
                require('@/assets/English-Final/2-front.jpg'),
                require('@/assets/English-Final/2-back.jpg'),
                require('@/assets/English-Final/3-front.jpg'),
                require('@/assets/English-Final/3-back.jpg'),
                require('@/assets/English-Final/4-front.jpg'),
                require('@/assets/English-Final/4-back.jpg'),
                require('@/assets/English-Final/5-front.jpg'),
                require('@/assets/English-Final/5-back.jpg'),
                require('@/assets/English-Final/6-front.jpg'),
                require('@/assets/English-Final/6-back.jpg'),
                require('@/assets/English-Final/7-front.jpg'),
                require('@/assets/English-Final/7-back.jpg'),
                require('@/assets/English-Final/8-front.jpg'),
                require('@/assets/English-Final/8-back.jpg'),
                require('@/assets/English-Final/9-front.jpg'),
                require('@/assets/English-Final/9-back.jpg'),
                // Add more thumbnail image URLs as needed
            ],
        };
    },
    methods: {
        slideTo(index) {
            this.currentSlide = index;
        },
        prevSlide() {
            this.currentSlide = Math.max(this.currentSlide - 1, 0);
        },
        nextSlide() {
            this.currentSlide = Math.min(this.currentSlide + 1, this.galleryImages.length - 1);
        },
    },
});
</script>

<style scoped>
.gallery-container {
    max-width: 800px;
    margin: 0 auto;
}

.carousel__item {
    cursor: pointer;
}

.carousel__item img {
    transition: transform 0.3s ease-in-out;
}

.carousel__item img:hover {
    transform: scale(1.05);
}
</style>
